<script lang="ts" setup>
  import type { AspectRatioPair, MediaRatios, VideoMimeType } from '@/types/media'

  const props = withDefaults(
    defineProps<{
      loading?: 'lazy' | 'eager'
      loop?: boolean
      muted?: boolean
      preload?: 'auto' | 'metadata' | 'none'
      ratio?: string | undefined
      ratios?: MediaRatios<string> | undefined
      src: string
      type: VideoMimeType
    }>(),
    {
      loading: 'lazy',
      loop: true,
      muted: true,
      preload: 'auto',
      ratio: undefined,
      ratios: undefined
    }
  )

  const aspectRatio = computed<AspectRatioPair | undefined>(() => {
    if (!props.ratios || !props.ratio) {
      return undefined
    }
    return props.ratios[props.ratio]
  })

  const videoElement = ref()

  /**
   * @description Loads and plays the video when it is visible in the viewport.
   * Note: This is only used when the lazy prop is set to true.
   * Note: This does not unload the video when element exists the viewport.
   * @see https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide
   */
  useIntersectionObserver(videoElement, ([{ isIntersecting }], observer) => {
    if (isIntersecting && props.loading === 'lazy') {
      videoElement.value?.play()
      observer.disconnect()
    }
  })
</script>

<template>
  <video
    ref="videoElement"
    :autoplay="loading !== 'lazy'"
    :loop="loop"
    :muted="muted"
    :playsinline="loading !== 'lazy'"
    :preload="loading === 'lazy' ? preload : 'metadata'"
    :style="{
      '--aspect-ratio-desktop': !!aspectRatio
        ? `${aspectRatio.desktop.numerator} / ${aspectRatio.desktop.denominator}`
        : undefined,
      '--aspect-ratio-mobile': !!aspectRatio
        ? `${aspectRatio.mobile.numerator} / ${aspectRatio.mobile.denominator}`
        : undefined
    }"
    class="c-video"
  >
    <source :src="src" :type="type" />
    Your browser does not support the video tag.
  </video>
</template>

<style lang="scss" scoped>
  .c-video {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
</style>
