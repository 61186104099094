import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeDatePromotionRuleFields {
  description: EntryFieldTypes.Symbol
  dateFrom: EntryFieldTypes.Date
  dateTo: EntryFieldTypes.Date
}

export type TypeDatePromotionRuleSkeleton = EntrySkeletonType<TypeDatePromotionRuleFields, 'datePromotionRule'>
export type TypeDatePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeDatePromotionRuleSkeleton,
  Modifiers,
  Locales
>

export function isTypeDatePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeDatePromotionRule<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'datePromotionRule'
}
