<script lang="ts" setup>
  import type { ImageProviderSource, ImgLoading, MediaRatios } from '@/types/media'
  import type { TypeImageTextComponent } from '~/types/contentful'

  const props = withDefaults(
    defineProps<{
      data: TypeImageTextComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      loading?: ImgLoading
      preload?: boolean
      provider?: ImageProviderSource
    }>(),
    {
      loading: 'lazy',
      preload: false,
      provider: 'contentful'
    }
  )

  const emit = defineEmits<{
    (event: 'primary-click'): void
    (event: 'secondary-click'): void
  }>()

  const ratios: MediaRatios<'S' | 'M' | 'L'> = {
    S: {
      desktop: {
        numerator: 1,
        denominator: 1
      },
      mobile: {
        numerator: 4,
        denominator: 3
      }
    },
    M: {
      desktop: {
        numerator: 5,
        denominator: 4
      },
      mobile: {
        numerator: 1,
        denominator: 1
      }
    },
    L: {
      desktop: {
        numerator: 2,
        denominator: 1
      },
      mobile: {
        numerator: 5,
        denominator: 6
      }
    }
  }

  const hasButtons = computed<boolean>(() => !!props.data.fields.primaryButton || !!props.data.fields.secondaryButton)

  const isDarkMode = computed<boolean>(() => props.data.fields.base?.fields.theme === 'Dark')

  const assetSrc = computed<string | undefined>(() => {
    return props.data.fields.image?.fields.file?.url
  })
</script>

<template>
  <section
    :class="[
      'm-image-and-text',
      'module-paddings',
      {
        'prop-align-left': data.fields.imageAlignment === 'Left',
        'prop-align-right': data.fields.imageAlignment === 'Right'
      }
    ]"
  >
    <div v-if="assetSrc" class="section-image">
      <CImageBlock
        :alt="data.fields.image?.fields.description"
        :config="data.fields.base"
        :loading
        :preload
        :provider
        :ratio="data.fields.ratio"
        :ratios
        :src="assetSrc"
      />
    </div>

    <div
      v-if="data.fields.text"
      :class="[
        'section-text',
        {
          'prop-text-align-left': data.fields.text.fields.alignment === 'Left',
          'prop-text-align-center': data.fields.text.fields.alignment === 'Centre',
          'prop-text-align-right': data.fields.text.fields.alignment === 'Right'
        }
      ]"
    >
      <CTextBlock :dark-mode="isDarkMode" :link-button="data.fields.linkButton" :text="data.fields.text" />

      <CButtonBlock
        v-if="hasButtons"
        :dark="isDarkMode"
        :primary-button="data.fields.primaryButton"
        :secondary-button="data.fields.secondaryButton"
        class="button-block"
        @primary-click="emit('primary-click')"
        @secondary-click="emit('secondary-click')"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .m-image-and-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 24px;
    column-gap: 64px;

    background-color: v-bind('data.fields.base?.fields.backgroundColour');
  }

  @include screenMdAndUp {
    .section-image,
    .section-text {
      flex: 1 1 0;
      width: 0;
    }
  }

  .section-text {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .m-image-and-text.prop-align-left {
    @include screenMdAndUp {
      flex-direction: row;
    }
  }

  .m-image-and-text.prop-align-right {
    @include screenMdAndUp {
      flex-direction: row-reverse;
    }
  }

  .section-text.prop-text-align-left {
    text-align: start;

    .button-block {
      justify-content: start;
    }
  }

  .section-text.prop-text-align-center {
    text-align: center;

    .button-block {
      justify-content: center;
    }
  }

  .section-text.prop-text-align-right {
    text-align: end;

    .button-block {
      justify-content: end;
    }
  }
</style>
