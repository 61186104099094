const PEEK_SOLID_WIDTH = 232 // 116px on each side
const PEEK_FADE_OUT_WIDTH = 160 // 80px on each side

export const MIN_SLIDE_COUNT = 6
export const SPACE_BETWEEN = 32

//   Desktop dimensions
const WIDTH_DESKTOP_SLIDE = 1104
export const WIDTH_DESKTOP_SLIDE_AND_GAP = WIDTH_DESKTOP_SLIDE + SPACE_BETWEEN
//     peek (solid) = 116px on each side
export const WIDTH_DESKTOP_SOLID_PEEK = WIDTH_DESKTOP_SLIDE_AND_GAP + PEEK_SOLID_WIDTH + SPACE_BETWEEN
//     peek (fade out) = 80px on each side
export const WIDTH_DESKTOP_CAROUSEL = WIDTH_DESKTOP_SOLID_PEEK + PEEK_FADE_OUT_WIDTH
// Height - max height of the carousel
export const HEIGHT_DESKTOP_CAROUSEL = 552
// slides per view desktop (depends on the width of the carousel)
export const SLIDES_PER_VIEW_DESKTOP = WIDTH_DESKTOP_CAROUSEL / WIDTH_DESKTOP_SLIDE_AND_GAP

//  Mobile dimensions
const WIDTH_MOBILE_SLIDE = 674
export const WIDTH_MOBILE_SLIDE_AND_GAP = WIDTH_MOBILE_SLIDE + SPACE_BETWEEN
//     there is no fade out peek on mobile
export const WIDTH_MOBILE_CAROUSEL = WIDTH_MOBILE_SLIDE_AND_GAP + SPACE_BETWEEN
// Height - calculated from slide width and 5:6 ratio
export const HEIGHT_MOBILE_CAROUSEL = WIDTH_MOBILE_SLIDE * (6 / 5)
// slides per view mobile (fixed)
export const SLIDES_PER_VIEW_MOBILE = 1.13
