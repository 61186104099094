<script lang="ts" setup>
  import type { Entry } from 'contentful'
  import {
    isTypeAccordionComponent,
    isTypeCardComponent,
    isTypeDividerComponent,
    isTypeHeroComponent,
    isTypeImageComponent,
    isTypeImageTextComponent,
    isTypePromotionComponent,
    isTypeSpacerComponent,
    isTypeTextComponent,
    isTypeYouTubeComponent,
    type TypeAccordionComponent,
    type TypeCardComponent,
    type TypeDividerComponent,
    type TypeHeroComponent,
    type TypeImageComponent,
    type TypeImageTextComponent,
    type TypePageSkeleton,
    type TypePromotionComponent,
    type TypeSpacerComponent,
    type TypeTextComponent,
    type TypeYouTubeComponent
  } from '@/types/contentful'
  import { definedItems } from '@/utils/contentful'
  import type { ImgLoading } from '@/types/media'

  const props = withDefaults(
    defineProps<{
      pageData: Entry<TypePageSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
      eagerLoadModuleAmount?: number
      preloadModuleAmount?: number
    }>(),
    {
      eagerLoadModuleAmount: 4,
      preloadModuleAmount: 2
    }
  )

  const { relishCheck } = useConditionalDisplay()

  /**
   * @description Determines if a module should be eagerly loaded or lazily loaded to improve FCP.
   * As the app is SSR, we cannot determine which modules are above the fold,
   * thus we want manually define force modules, which contain images, to eagerly load.
   * @param index {number} - The index of the module in the array.
   */
  function shouldEagerLoadModule(index: number): ImgLoading {
    return index + 1 <= props.eagerLoadModuleAmount ? 'eager' : 'lazy'
  }

  /**
   * @description Determines if a module should be preloaded to improve LCP.
   * @param index {number} - The index of the module in the array.
   */
  function shouldPreloadModule(index: number): boolean {
    return index + 1 <= props.preloadModuleAmount
  }
</script>

<template>
  <template v-for="(component, index) in definedItems(pageData.fields.components)" :key="component.sys.id">
    <template v-if="relishCheck(component.fields.base?.fields?.visibility)">
      <MAccordionDrawer
        v-if="isTypeAccordionComponent(component)"
        :data="component as TypeAccordionComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
      />

      <MCardCarousel
        v-if="isTypeCardComponent(component)"
        :data="component as TypeCardComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
        :loading="shouldEagerLoadModule(index)"
        :preload="shouldPreloadModule(index)"
      />

      <MDivider
        v-if="isTypeDividerComponent(component)"
        :data="component as TypeDividerComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
      />

      <MTextAndButtons
        v-if="isTypeTextComponent(component)"
        :data="component as TypeTextComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
      />

      <MImage
        v-if="isTypeImageComponent(component)"
        :data="component as TypeImageComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
        :loading="shouldEagerLoadModule(index)"
        :preload="shouldPreloadModule(index)"
      />

      <MImageAndText
        v-if="isTypeImageTextComponent(component)"
        :data="component as TypeImageTextComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
        :loading="shouldEagerLoadModule(index)"
        :preload="shouldPreloadModule(index)"
      />

      <MHero
        v-if="isTypeHeroComponent(component)"
        :data="component as TypeHeroComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
        :loading="shouldEagerLoadModule(index)"
        :preload="shouldPreloadModule(index)"
      />

      <MSpacer
        v-if="isTypeSpacerComponent(component)"
        :data="component as TypeSpacerComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
      />

      <MYouTube
        v-if="isTypeYouTubeComponent(component)"
        :data="component as TypeYouTubeComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
      />

      <MPromotions
        v-if="isTypePromotionComponent(component)"
        :data="component as TypePromotionComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>"
        :loading="shouldEagerLoadModule(index)"
        :preload="shouldPreloadModule(index)"
      />
    </template>
  </template>
</template>
