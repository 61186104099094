import * as contentful from 'contentful'
import type { JsonObject, JsonValue } from 'contentful/node_modules/type-fest'
import type { Title } from '@unhead/schema'
import { DEFAULT_SEO_META } from '@/constants/seo'
import type { TypePageSeoSkeleton } from '@/types/contentful'
import { getImageMetaFields } from '@/utils/seo'

interface GrilldSeoHeadProps {
  pageTitle?: Title | Promise<Title>
  canonicalUrl?: string
  jsonLinkingData?: JsonObject | readonly JsonValue[]
}

/**
 * @description Reactively updates the SEO meta tags, canonical URL, and JSON-LD script
 * @param pageTitle - Page title
 * @param restaurantName - Restaurant name
 * @param jsonLinkingData - JSON-LD SEO data
 * @returns void
 */
export function useGrilldSeoHead({ pageTitle, canonicalUrl, jsonLinkingData }: GrilldSeoHeadProps): void {
  const jsonLinkingDataArray = []

  if (jsonLinkingData) {
    if (Array.isArray(jsonLinkingData)) {
      const jsonLinkingDataFlat = jsonLinkingData.flat(Infinity)
      jsonLinkingDataFlat.forEach((el) => {
        if (el) {
          jsonLinkingDataArray.push({
            type: 'application/ld+json',
            children: JSON.stringify(el, null, 2)
          })
        }
      })
    } else {
      jsonLinkingDataArray.push({
        type: 'application/ld+json',
        children: JSON.stringify(jsonLinkingData, null, 2)
      })
    }
  }

  useHead({
    // Page title
    title: pageTitle || DEFAULT_SEO_META.title,
    // link rel="canonical"
    link: () => {
      if (!canonicalUrl) {
        return []
      } else {
        return [
          {
            rel: 'canonical',
            href: canonicalUrl
          }
        ]
      }
    },
    // JSON-LD SEO data
    script: jsonLinkingDataArray
  })
}

/**
 * @description Converts a Contentful SEO entry and updates the server-side SEO meta tags
 * @param seo - Contentful SEO entry
 * @returns void
 */
export function useGrilldServerSeoMeta(
  seo: contentful.Entry<TypePageSeoSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined
): void {
  const metaImage = getImageMetaFields(seo)

  useServerSeoMeta({
    title: seo?.fields.metaTitle || DEFAULT_SEO_META.title,
    ogTitle: seo?.fields.metaTitle,
    description: seo?.fields.metaDescription,
    ogDescription: seo?.fields.metaDescription,
    ogSiteName: DEFAULT_SEO_META.ogSiteName,
    ogLocale: DEFAULT_SEO_META.ogLocale,
    ogType: DEFAULT_SEO_META.ogType,
    ogUrl: seo?.fields.canonicalUrl,
    robots: seo?.fields.metaRobots,
    ...metaImage
  })
}
