<script lang="ts" setup>
  import type * as contentful from 'contentful'
  import type * as CSS from 'csstype'
  import {
    isTypeAppButtonBlock,
    isTypeButtonBlock,
    type TypeAppButtonBlockSkeleton,
    type TypeButtonBlockSkeleton
  } from '@/types/contentful'

  withDefaults(
    defineProps<{
      dark: boolean
      primaryButton:
        | contentful.Entry<TypeButtonBlockSkeleton | TypeAppButtonBlockSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
        | undefined
      secondaryButton:
        | contentful.Entry<TypeButtonBlockSkeleton | TypeAppButtonBlockSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string>
        | undefined
      flexDirection?: CSS.Property.FlexDirection // Only applies to mobile screens
    }>(),
    {
      flexDirection: 'column'
    }
  )

  const emit = defineEmits<{
    (event: 'primary-click'): void
    (event: 'secondary-click'): void
  }>()
</script>

<template>
  <div class="c-button-block">
    <!-- Primary Button -->

    <CLink
      v-if="primaryButton && isTypeButtonBlock(primaryButton)"
      :href="primaryButton.fields.href"
      :is-embedded-app-link="primaryButton.fields.target === 'App'"
      :target="primaryButton.fields.target === 'Tab' ? '_blank' : undefined"
      @click="emit('primary-click')"
    >
      <CButton :dark-mode="dark" class="normal-button" variant="primary">
        {{ primaryButton.fields.title }}
      </CButton>
    </CLink>

    <CAppStoreButton v-if="primaryButton && isTypeAppButtonBlock(primaryButton)" :store="primaryButton.fields.slug" />

    <!-- Secondary Button -->

    <CLink
      v-if="secondaryButton && isTypeButtonBlock(secondaryButton)"
      :href="secondaryButton.fields.href"
      :is-embedded-app-link="secondaryButton.fields.target === 'App'"
      :target="secondaryButton.fields.target === 'Tab' ? '_blank' : undefined"
      @click="emit('secondary-click')"
    >
      <CButton v-if="secondaryButton" :dark-mode="dark" class="normal-button" variant="secondary">
        {{ secondaryButton.fields.title }}
      </CButton>
    </CLink>

    <CAppStoreButton
      v-if="secondaryButton && isTypeAppButtonBlock(secondaryButton)"
      :store="secondaryButton.fields.slug"
    />
  </div>
</template>

<style lang="scss" scoped>
  .c-button-block {
    display: flex;
    flex-direction: v-bind(flexDirection);
    justify-content: center;
    width: 100%;

    gap: 1rem;

    .normal-button,
    .normal-button > * {
      width: 100%;
    }

    @include screenMdAndUp {
      flex-direction: row;

      .normal-button,
      .normal-button > * {
        width: initial;
      }
    }
  }
</style>
