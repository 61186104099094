import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeAppButtonBlockSkeleton } from './TypeAppButtonBlock'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeButtonBlockSkeleton } from './TypeButtonBlock'
import type { TypeTextBlockSkeleton } from './TypeTextBlock'

export interface TypeHeroComponentFields {
  title: EntryFieldTypes.Symbol
  text: EntryFieldTypes.EntryLink<TypeTextBlockSkeleton>
  primaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  secondaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  linkButton?: EntryFieldTypes.EntryLink<TypeButtonBlockSkeleton>
  desktopImage: EntryFieldTypes.AssetLink
  mobileImage: EntryFieldTypes.AssetLink
  size: EntryFieldTypes.Symbol<'L' | 'M' | 'S'>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeHeroComponentSkeleton = EntrySkeletonType<TypeHeroComponentFields, 'heroComponent'>
export type TypeHeroComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeHeroComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeHeroComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeHeroComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'heroComponent'
}
