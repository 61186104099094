import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeYouTubeComponentFields {
  title: EntryFieldTypes.Symbol
  videoId: EntryFieldTypes.Symbol
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeYouTubeComponentSkeleton = EntrySkeletonType<TypeYouTubeComponentFields, 'youTubeComponent'>
export type TypeYouTubeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeYouTubeComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeYouTubeComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeYouTubeComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'youTubeComponent'
}
