<script lang="ts" setup>
  import type * as CSS from 'csstype'

  const props = withDefaults(
    defineProps<{
      color?: CSS.Property.Color
      backgroundColor?: CSS.Property.BackgroundColor
      variant?: 'Solid' | 'Dotted'
    }>(),
    {
      color: 'var(--color-stroke-medium)',
      backgroundColor: 'inherit',
      variant: 'Dotted'
    }
  )

  const height = computed(() => (props.variant === 'Dotted' ? '6px' : '4px'))
</script>

<template>
  <div class="c-divider">
    <hr :class="`divider-${variant.toLowerCase()}`" />
  </div>
</template>

<style lang="scss" scoped>
  .c-divider {
    height: 8px;
    background-color: v-bind('backgroundColor');
  }

  hr {
    height: v-bind('height');
    background-position: bottom;
    background-size:
      14px 4px,
      4px 14px;
    background-repeat: repeat-x;
    border: none;
  }

  .divider-dotted {
    background-image: radial-gradient(circle at 1px, v-bind('color') 1px, rgba(255, 255, 255, 0) 1px);
  }

  .divider-solid {
    border-bottom: 1px solid v-bind('color');
  }
</style>
