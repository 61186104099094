import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeSpacerComponentFields {
  title: EntryFieldTypes.Symbol
  size: EntryFieldTypes.Symbol<'L' | 'M' | 'S' | 'XL' | 'XS'>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeSpacerComponentSkeleton = EntrySkeletonType<TypeSpacerComponentFields, 'spacerComponent'>
export type TypeSpacerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeSpacerComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeSpacerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeSpacerComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'spacerComponent'
}
