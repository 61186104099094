<script lang="ts" setup>
  import { documentToHtmlString, type Options } from '@contentful/rich-text-html-renderer'
  import { type Document, BLOCKS, INLINES } from '@contentful/rich-text-types'

  const props = defineProps<{
    text: Document
  }>()

  const options: Partial<Options> = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, next) => {
        // If the paragraph is empty, return a <br/> tag instead of an empty <p> tag
        // to avoid the browser collapsing the empty <p> tag
        if (
          node.nodeType === 'paragraph' &&
          node.content.length === 1 &&
          node.content[0].nodeType === 'text' &&
          node.content[0].value === ''
        ) {
          return '<br/>'
        }

        return `<p>${next(node.content)}</p>`
      },
      [INLINES.HYPERLINK]: (node, next) => {
        return `<a class="simple-text-link" href="${node.data.uri}">${next(node.content)}</a>`
      }
    }
  }

  const textContent = computed<string>(() => {
    return documentToHtmlString(props.text as Document, options)
  })
</script>

<template>
  <div v-if="textContent" class="c-body-rich-text body" v-html="textContent" />
</template>
