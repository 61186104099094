import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeStatePromotionRuleFields {
  description: EntryFieldTypes.Symbol
  state: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<
      | 'Australian Capital Territory'
      | 'New South Wales'
      | 'Northern Territory'
      | 'Queensland'
      | 'South Australia'
      | 'Tasmania'
      | 'Victoria'
      | 'Western Australia'
    >
  >
}

export type TypeStatePromotionRuleSkeleton = EntrySkeletonType<TypeStatePromotionRuleFields, 'statePromotionRule'>
export type TypeStatePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeStatePromotionRuleSkeleton,
  Modifiers,
  Locales
>

export function isTypeStatePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeStatePromotionRule<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'statePromotionRule'
}
