import * as contentful from 'contentful'
import type { UseSeoMetaInput } from '@unhead/schema'
import type { TypePageSeoSkeleton } from '@/types/contentful'
import { DEFAULT_SEO_META_IMAGE } from '@/constants/seo'

/**
 * @description Type-safe image type. The only allowed image types are 'image/jpeg', 'image/gif', and 'image/png'.
 * Contentful may return other image types, so this function will return undefined for those cases.
 * @param contentTypeFromCms - Contentful image content type
 * @returns 'image/jpeg', 'image/gif', 'image/png', or undefined
 */
function typeSafeImageType(contentTypeFromCms: string | undefined): UseSeoMetaInput['ogImageType'] | undefined {
  switch (contentTypeFromCms) {
    case 'image/jpeg':
    case 'image/gif':
    case 'image/png':
      return contentTypeFromCms
    default:
      return undefined
  }
}

/**
 * @description Extracts image meta fields from a Contentful SEO entry
 * @param seo - Contentful SEO entry
 * @returns Image meta fields
 */
export function getImageMetaFields(
  seo: contentful.Entry<TypePageSeoSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS', string> | undefined
) {
  if (
    seo?.fields.metaImage?.fields.file?.contentType &&
    typeSafeImageType(seo?.fields.metaImage?.fields.file?.contentType)
  ) {
    return {
      ogImage: seo.fields.metaImage.fields.file.url,
      ogImageAlt: seo.fields.metaImage.fields.description,
      ogImageType: seo.fields.metaImage.fields.file.contentType as UseSeoMetaInput['ogImageType'],
      ogImageHeight: seo.fields.metaImage.fields.file.details.image!.height,
      ogImageWidth: seo.fields.metaImage?.fields.file.details.image!.width
    } as const
  } else {
    return DEFAULT_SEO_META_IMAGE
  }
}

/**
 * @description Removes "Grill'd" from the restaurant name and returns the sanitized name
 * @param restaurantName {string} - The name of the restaurant
 * @returns {string} - The sanitized restaurant name
 */
export function sanitizeRestaurantName(restaurantName: string): string {
  const res = restaurantName.replace('Grill’d', '').trim()
  console.log('sanitizeRestaurantName', restaurantName, res)
  return res
}

export function getRestaurantTitle(restaurantName?: string): string {
  return `Grill'd Burgers${' ' + restaurantName} - Healthy Burger Restaurant`
}

/**
 * @description Creates a meta description for a restaurant page
 * @param restaurantName {string} - The name of the restaurant
 * @returns {string} - The meta description
 */
export function getRestaurantMetaDescription(restaurantName?: string): string {
  return `Here at Grill'd ${restaurantName}, we’re all about tasty, fresh, healthy burgers. Whether you're a vegetarian, vegan, gluten free or all in. Dine in restaurant, order online or via Grill'd app to enjoy your Relish benefits.`
}
