import type { Title, UseSeoMetaInput } from '@unhead/schema'
import { GRILLD_URL } from '@/constants/url'

export const DEFAULT_SEO_META_TITLE: Title = "Grill'd Healthy Burgers"
export const DEFAULT_SEO_META_DESCRIPTION: string =
  'Grill’d Healthy Burgers are Australian, healthy, and ethical. Our burgers are made with 100% natural Aussie produce. Visit our site to learn more!'

export const DEFAULT_SEO_META_IMAGE = {
  ogImage: GRILLD_URL + '/assets/seo-default-image.jpeg',
  ogImageAlt: DEFAULT_SEO_META_TITLE,
  ogImageHeight: 1200,
  ogImageType: 'image/jpeg',
  ogImageWidth: 630
} as const

export const DEFAULT_SEO_META: UseSeoMetaInput = {
  ...DEFAULT_SEO_META_IMAGE,
  title: DEFAULT_SEO_META_TITLE as Title,
  ogTitle: DEFAULT_SEO_META_TITLE,
  description: DEFAULT_SEO_META_DESCRIPTION,
  ogDescription: DEFAULT_SEO_META_DESCRIPTION,
  ogSiteName: DEFAULT_SEO_META_TITLE,
  ogLocale: 'en_AU',
  ogType: 'website',
  robots: 'index, follow'
}
