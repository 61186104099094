import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeAppButtonBlockSkeleton } from './TypeAppButtonBlock'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeButtonBlockSkeleton } from './TypeButtonBlock'
import type { TypeTextBlockSkeleton } from './TypeTextBlock'

export interface TypeTextComponentFields {
  title: EntryFieldTypes.Symbol
  text: EntryFieldTypes.EntryLink<TypeTextBlockSkeleton>
  primaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  secondaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  linkButton?: EntryFieldTypes.EntryLink<TypeButtonBlockSkeleton>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeTextComponentSkeleton = EntrySkeletonType<TypeTextComponentFields, 'textComponent'>
export type TypeTextComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeTextComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeTextComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeTextComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'textComponent'
}
