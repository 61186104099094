import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeTagPromotionRuleFields {
  description: EntryFieldTypes.Symbol
  path: EntryFieldTypes.Symbol
  value: EntryFieldTypes.Symbol
}

export type TypeTagPromotionRuleSkeleton = EntrySkeletonType<TypeTagPromotionRuleFields, 'tagPromotionRule'>
export type TypeTagPromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeTagPromotionRuleSkeleton,
  Modifiers,
  Locales
>

export function isTypeTagPromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeTagPromotionRule<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'tagPromotionRule'
}
