<script lang="ts" setup>
  import type { Document } from '@contentful/rich-text-types'
  import type { TypeAccordionComponent } from '@/types/contentful'

  const props = defineProps<{
    data: TypeAccordionComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
  }>()

  const isExpanded = ref<boolean>(false)

  watchEffect(() => {
    if (isExpanded.value) {
      gaEventAccordionExpanded()
      trackingEventAccordionExpanded(props.data.fields.title, props.data.sys.id)
    }
  })
</script>

<template>
  <div
    :class="[
      'm-accordion-drawer',
      'module-paddings-h',
      {
        'prop-dark-mode': data.fields.base?.fields.theme === 'Dark'
      }
    ]"
  >
    <CAccordionDrawer
      v-model="isExpanded"
      :border-color="data.fields.borderColour"
      :dark="data.fields.base?.fields.theme === 'Dark'"
      :title="data.fields.title"
    >
      <div class="panel-content">
        <CBodyRichText v-if="data.fields.body" :text="data.fields.body as Document" class="panel-content-body" />

        <CLink
          v-if="data.fields.linkButton"
          :class="['text-link', { 'text-link-dark': data.fields.base?.fields.theme === 'Dark' }, 'drawer-link']"
          :href="data.fields.linkButton.fields.href"
          :is-embedded-app-link="data.fields.linkButton.fields.target === 'App'"
          :target="data.fields.linkButton.fields.target === 'Tab' ? '_blank' : undefined"
        >
          {{ data.fields.linkButton?.fields.title }}
        </CLink>
      </div>
    </CAccordionDrawer>
  </div>
</template>

<style lang="scss" scoped>
  .m-accordion-drawer {
    padding-top: 8px;
    padding-bottom: 8px;

    background-color: v-bind('data.fields.base?.fields.backgroundColour');
    color: v-bind('data.fields.textColour');

    .panel-content {
      text-align: start;

      .drawer-link {
        display: inline-block;
        margin-top: 16px;
      }
    }
  }
</style>
