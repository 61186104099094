import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeCardBlockSkeleton } from './TypeCardBlock'

export interface TypeCardComponentFields {
  title: EntryFieldTypes.Symbol
  items: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCardBlockSkeleton>>
  alignment: EntryFieldTypes.Symbol<'Centre' | 'Left' | 'Right'>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeCardComponentSkeleton = EntrySkeletonType<TypeCardComponentFields, 'cardComponent'>
export type TypeCardComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeCardComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeCardComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCardComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'cardComponent'
}
