import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeImageComponentFields {
  title: EntryFieldTypes.Symbol
  image: EntryFieldTypes.AssetLink
  ratio: EntryFieldTypes.Symbol<'L' | 'M' | 'S' | 'XL' | 'XS' | 'XXS'>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeImageComponentSkeleton = EntrySkeletonType<TypeImageComponentFields, 'imageComponent'>
export type TypeImageComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeImageComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeImageComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeImageComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'imageComponent'
}
