export function transformKeysToLowercase(obj: Record<string, any>): Record<string, any> {
  const convertedObj: Record<string, any> = {}

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key]
      const lowercaseKey = key.toLowerCase()

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        convertedObj[lowercaseKey] = transformKeysToLowercase(value)
      } else {
        convertedObj[lowercaseKey] = value
      }
    }
  }

  return convertedObj
}
