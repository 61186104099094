<script lang="ts" setup>
  import type { AspectRatioPair, ImgLoading, MediaRatios } from '@/types/media'
  import type { TypeHeroComponent } from '~/types/contentful'

  const props = withDefaults(
    defineProps<{
      data: TypeHeroComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      loading?: ImgLoading
      preload?: boolean
    }>(),
    {
      loading: 'lazy',
      preload: false
    }
  )

  const aspectRatio = computed<AspectRatioPair>(() => ratios[props.data.fields.size])

  const hasButtons = computed<boolean>(() => !!props.data.fields.primaryButton || !!props.data.fields.secondaryButton)

  const isDarkMode = computed<boolean>(() => props.data.fields.base?.fields.theme === 'Dark')

  const ratios: MediaRatios<'L' | 'M' | 'S'> = {
    S: {
      desktop: {
        numerator: 4,
        denominator: 1
      },
      mobile: {
        numerator: 4,
        denominator: 3
      }
    },
    M: {
      desktop: {
        numerator: 3,
        denominator: 1
      },
      mobile: {
        numerator: 1,
        denominator: 1
      }
    },
    L: {
      desktop: {
        numerator: 2,
        denominator: 1
      },
      mobile: {
        numerator: 5,
        denominator: 6
      }
    }
  }
</script>

<template>
  <section class="m-hero">
    <div
      :style="{
        '--aspect-ratio-desktop': `${aspectRatio.desktop.numerator} / ${aspectRatio.desktop.denominator}`,
        '--aspect-ratio-mobile': `${aspectRatio.mobile.numerator} / ${aspectRatio.mobile.denominator}`
      }"
      class="hero-container"
    >
      <CImageBlock
        v-if="data.fields.desktopImage?.fields.file"
        :alt="data.fields.desktopImage.fields.description"
        :loading
        :preload
        :src="data.fields.desktopImage.fields.file.url"
        class="hero-image-desktop"
      />
      <CImageBlock
        v-if="data.fields.mobileImage?.fields.file"
        :alt="data.fields.mobileImage.fields.description"
        :loading
        :preload
        :src="data.fields.mobileImage.fields.file.url"
        class="hero-image-mobile"
      />

      <div
        :class="[
          'hero-content',
          ' module-paddings',
          {
            'prop-align-left': props.data.fields.text?.fields.alignment === 'Left',
            'prop-align-center': props.data.fields.text?.fields.alignment === 'Centre',
            'prop-align-right': props.data.fields.text?.fields.alignment === 'Right'
          }
        ]"
      >
        <div class="section-text">
          <CTextBlock
            :dark-mode="isDarkMode"
            :link-button="data.fields.linkButton"
            :text="data.fields.text"
            class="hero-text-block"
            overflow-wrap="normal"
          />

          <CButtonBlock
            v-if="hasButtons"
            :dark="isDarkMode"
            :primary-button="data.fields.primaryButton"
            :secondary-button="data.fields.secondaryButton"
            class="button-block"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .m-hero {
    background-color: v-bind('data.fields.base?.fields.backgroundColour');

    width: 100%;

    position: relative;
    overflow-y: hidden;

    .hero-container {
      // responsively change aspect ratio
      aspect-ratio: var(--aspect-ratio-mobile);
      @include screenMdAndUp {
        aspect-ratio: var(--aspect-ratio-desktop);
      }

      width: 100%;
      max-width: var(--breakpoint-xl);

      margin-left: auto;
      margin-right: auto;

      & > .hero-image-desktop,
      & > .hero-image-mobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // responsively change image
      .hero-image-desktop {
        display: none;
      }

      .hero-image-mobile {
        display: block;
      }

      @include screenMdAndUp {
        .hero-image-desktop {
          display: block;
        }

        .hero-image-mobile {
          display: none;
        }
      }

      .hero-content {
        position: absolute;
        bottom: 0;

        width: 100%;
        max-width: var(--breakpoint-xl);
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        // override (remove) the horizontal padding on XL+ screens that normally centres the module
        @include screenXlAndUp {
          padding-left: var(--padding-x-lg);
          padding-right: var(--padding-x-lg);
        }

        .section-text {
          display: flex;
          flex-direction: column;
          justify-content: center;

          min-height: 100%;
          width: 100%;

          @include screenMdAndUp {
            width: 80%;
          }

          @include screenLgAndUp {
            width: 60%;
          }

          @include screenXlAndUp {
            width: 40%;
          }

          .hero-text-block {
            flex: 1;
            justify-content: center;
          }
        }
      }
    }
  }

  .hero-content.prop-align-left {
    align-items: start;

    .section-text {
      text-align: start;

      .button-block {
        justify-content: start;
      }
    }
  }

  .hero-content.prop-align-center {
    align-items: center;

    .section-text {
      text-align: center;

      .button-block {
        justify-content: center;
      }
    }
  }

  .hero-content.prop-align-right {
    align-items: end;

    .section-text {
      text-align: end;

      .button-block {
        justify-content: end;
      }
    }
  }
</style>
