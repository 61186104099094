import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeAppButtonBlockFields {
  slug: EntryFieldTypes.Symbol<'Android' | 'iOS'>
}

export type TypeAppButtonBlockSkeleton = EntrySkeletonType<TypeAppButtonBlockFields, 'appButtonBlock'>
export type TypeAppButtonBlock<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeAppButtonBlockSkeleton,
  Modifiers,
  Locales
>

export function isTypeAppButtonBlock<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeAppButtonBlock<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'appButtonBlock'
}
