import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeButtonBlockSkeleton } from './TypeButtonBlock'

export interface TypeAccordionComponentFields {
  description: EntryFieldTypes.Symbol
  title: EntryFieldTypes.Symbol
  body: EntryFieldTypes.RichText
  textColour: EntryFieldTypes.Symbol
  borderColour: EntryFieldTypes.Symbol
  linkButton?: EntryFieldTypes.EntryLink<TypeButtonBlockSkeleton>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeAccordionComponentSkeleton = EntrySkeletonType<TypeAccordionComponentFields, 'accordionComponent'>
export type TypeAccordionComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeAccordionComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeAccordionComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeAccordionComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'accordionComponent'
}
