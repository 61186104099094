<script lang="ts" setup>
  import type { CCardItemData } from '@/components/common/CCardItem/CCardItem.types'
  import type { ImageProviderSource, ImgLoading } from '@/types/media'

  const props = withDefaults(
    defineProps<{
      alignment?: 'left' | 'centre' | 'right'
      backgroundColor?: string
      darkMode?: boolean
      items: CCardItemData[]
      loading: ImgLoading
      preload?: boolean
      provider?: ImageProviderSource
    }>(),
    {
      alignment: 'left',
      backgroundColor: 'inherit',
      darkMode: false,
      loading: 'lazy',
      preload: false,
      provider: 'contentful'
    }
  )

  const slideLength = computed<number>(() => props.items.length)
</script>

<template>
  <client-only>
    <Swiper
      :breakpoints="{
        0: {
          slidesPerView: 1.6,
          slidesOffsetBefore: 16,
          slidesOffsetAfter: 16,
          spaceBetween: 16,
          navigation: {
            enabled: false
          }
        },
        576: {
          slidesPerView: 2.2,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          spaceBetween: 16,
          navigation: {
            enabled: false
          }
        },
        768: {
          slidesPerView: 3.14,
          spaceBetween: 32,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          navigation: {
            enabled: true
          }
        },
        992: {
          slidesPerView: slideLength > 3 ? 4.16 : 3.14,
          spaceBetween: 32,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          navigation: {
            enabled: true
          }
        },
        1200: {
          slidesPerView: slideLength > 3 ? 4.14 : 3.1,
          spaceBetween: 32,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          navigation: {
            enabled: true
          }
        },
        1400: {
          slidesPerView: slideLength > 3 ? 4.14 : 3.1,
          spaceBetween: 32,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          navigation: {
            enabled: true
          }
        }
      }"
      :modules="[SwiperNavigation]"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }"
      class="c-card-carousel"
    >
      <SwiperSlide v-for="item in items" :key="item.id" class="card-carousel-slide">
        <CCardItem
          :alignment
          :aspect-ratio="{
            desktop: { denominator: 4, numerator: 5 },
            mobile: { denominator: 4, numerator: 5 }
          }"
          :dark-mode="darkMode"
          :data="item"
          :loading
          :preload
          :provider
          class="slide-card-item"
        />
      </SwiperSlide>

      <CCarouselNavigationButtons :gradient-color="backgroundColor" />
    </Swiper>
  </client-only>
</template>

<style lang="scss" scoped>
  .c-card-carousel {
    background-color: v-bind('backgroundColor');

    .card-carousel-slide {
      display: flex;
      height: auto;

      .slide-card-item {
        width: 100%;
        height: 100%;

        padding-bottom: 1px;
      }
    }
  }
</style>
