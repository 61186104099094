<script lang="ts" setup>
  import type { CCardItemAlignment, CCardItemData } from '@/components/common/CCardItem/CCardItem.types'
  import type { TypeCardComponent } from '@/types/contentful'
  import type { ImgLoading } from '@/types/media'
  import { definedItems } from '@/utils/contentful'

  const props = withDefaults(
    defineProps<{
      data: TypeCardComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      loading?: ImgLoading
      preload?: boolean
    }>(),
    {
      loading: 'lazy',
      preload: false
    }
  )

  const alignment = computed(() => props.data.fields.alignment.toLowerCase() as CCardItemAlignment)
  const cards = computed(() => transformCardData(props.data))

  /**
   * @description Transform the card data to the format required by the CCardCarousel component
   * @param data The card data from CMS
   * @returns The transformed card data
   */
  function transformCardData(data: TypeCardComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>): CCardItemData[] {
    const filteredItems = definedItems(data.fields.items) || []

    return filteredItems
      .map((item) => {
        // Filter out items that have no fields (i.e. draft items)
        if (!Object.hasOwn(item, 'fields')) {
          return undefined
        }

        return {
          id: item.sys.id,
          title: item.fields.title || '',
          body: item.fields.body || '',
          button: item.fields.button
            ? {
                title: item.fields.button.fields.title,
                href: item.fields.button.fields.href,
                target: item.fields.button.fields.target
              }
            : undefined,
          image: item.fields.image
            ? {
                alt: item.fields.image.fields.description,
                url: item.fields.image.fields.file!.url
              }
            : undefined
        }
      })
      .filter((item) => item !== undefined) as CCardItemData[]
  }
</script>

<template>
  <section class="m-card-carousel module-paddings-h">
    <CCardCarousel
      :alignment
      :background-color="data.fields.base?.fields.backgroundColour"
      :dark-mode="data.fields.base?.fields.theme === 'Dark'"
      :items="cards"
      :loading
      :preload
    />
  </section>
</template>

<style lang="scss" scoped>
  .m-card-carousel {
    min-height: 192px; // the calculated image height of the carousel at 393px width
    width: 100%;
    margin: 0 auto;
    padding: 8px 0;

    background-color: v-bind('data.fields.base?.fields.backgroundColour');

    @include screenSmAndUp {
      min-height: 202px; // the calculated image height of the carousel at 576px width
    }

    @include screenMdAndUp {
      min-height: 178px; // the calculated image height of the carousel at 768px width
      padding: 12px 0;
    }

    @include screenLgAndUp {
      min-height: 235px; // the calculated image height of the carousel at 992px width
    }

    @include screenXlAndUp {
      min-height: 344px; // the calculated image height of the carousel at 1440px width
    }

    :deep(.c-card-carousel) {
      max-width: 1400px;
    }
  }
</style>
