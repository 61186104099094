import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'

export interface TypeDividerComponentFields {
  title: EntryFieldTypes.Symbol
  style: EntryFieldTypes.Symbol<'Dotted' | 'Solid'>
  colour: EntryFieldTypes.Symbol
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeDividerComponentSkeleton = EntrySkeletonType<TypeDividerComponentFields, 'dividerComponent'>
export type TypeDividerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeDividerComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeDividerComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeDividerComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'dividerComponent'
}
