import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeRelishPromotionRuleFields {
  description: EntryFieldTypes.Symbol
  visibility: EntryFieldTypes.Symbol<'Guest' | 'Relish'>
}

export type TypeRelishPromotionRuleSkeleton = EntrySkeletonType<TypeRelishPromotionRuleFields, 'relishPromotionRule'>
export type TypeRelishPromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeRelishPromotionRuleSkeleton,
  Modifiers,
  Locales
>

export function isTypeRelishPromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeRelishPromotionRule<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'relishPromotionRule'
}
