import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'

export interface TypeTimePromotionRuleFields {
  description: EntryFieldTypes.Symbol
  timeFrom: EntryFieldTypes.Integer<
    0 | 1 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 2 | 20 | 21 | 22 | 23 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  >
  timeTo: EntryFieldTypes.Integer<
    0 | 1 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 2 | 20 | 21 | 22 | 23 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  >
  day: EntryFieldTypes.Array<
    EntryFieldTypes.Symbol<'Friday' | 'Monday' | 'Saturday' | 'Sunday' | 'Thursday' | 'Tuesday' | 'Wednesday'>
  >
}

export type TypeTimePromotionRuleSkeleton = EntrySkeletonType<TypeTimePromotionRuleFields, 'timePromotionRule'>
export type TypeTimePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeTimePromotionRuleSkeleton,
  Modifiers,
  Locales
>

export function isTypeTimePromotionRule<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeTimePromotionRule<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'timePromotionRule'
}
