import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeAppButtonBlockSkeleton } from './TypeAppButtonBlock'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeButtonBlockSkeleton } from './TypeButtonBlock'
import type { TypeTextBlockSkeleton } from './TypeTextBlock'

export interface TypeImageTextComponentFields {
  title: EntryFieldTypes.Symbol
  image: EntryFieldTypes.AssetLink
  ratio: EntryFieldTypes.Symbol<'L' | 'M' | 'S'>
  imageAlignment: EntryFieldTypes.Symbol<'Left' | 'Right'>
  text: EntryFieldTypes.EntryLink<TypeTextBlockSkeleton>
  primaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  secondaryButton?: EntryFieldTypes.EntryLink<TypeAppButtonBlockSkeleton | TypeButtonBlockSkeleton>
  linkButton?: EntryFieldTypes.EntryLink<TypeButtonBlockSkeleton>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypeImageTextComponentSkeleton = EntrySkeletonType<TypeImageTextComponentFields, 'imageTextComponent'>
export type TypeImageTextComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypeImageTextComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypeImageTextComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeImageTextComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'imageTextComponent'
}
