import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from 'contentful'
import type { TypeBaseComponentSkeleton } from './TypeBaseComponent'
import type { TypeWebPromotionSkeleton } from './TypeWebPromotion'

export interface TypePromotionComponentFields {
  title: EntryFieldTypes.Symbol
  items: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeWebPromotionSkeleton>>
  base: EntryFieldTypes.EntryLink<TypeBaseComponentSkeleton>
}

export type TypePromotionComponentSkeleton = EntrySkeletonType<TypePromotionComponentFields, 'promotionComponent'>
export type TypePromotionComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode> = Entry<
  TypePromotionComponentSkeleton,
  Modifiers,
  Locales
>

export function isTypePromotionComponent<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypePromotionComponent<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'promotionComponent'
}
