<script lang="ts" setup>
  import type * as CSS from 'csstype'

  withDefaults(
    defineProps<{
      title: string
      borderColor?: CSS.Property.BorderColor
      dark?: boolean
    }>(),
    {
      borderColor: 'var(--color-stroke-light)',
      dark: false
    }
  )

  const [isExpanded] = defineModel<boolean>({
    default: false,
    required: true
  })

  const panelRef = ref<HTMLDivElement | null>(null)

  function toggleExpanded() {
    isExpanded.value = !isExpanded.value
  }

  watchEffect(() => {
    if (panelRef.value) {
      if (isExpanded.value) {
        panelRef.value.style.maxHeight = `${panelRef.value.scrollHeight + 16}px`
      } else {
        panelRef.value.style.maxHeight = '0px'
      }
    }
  })
</script>

<template>
  <div :class="['c-accordion-drawer', { 'active': isExpanded, 'dark-mode': dark }]">
    <div class="accordion-drawer-trigger" @click="toggleExpanded" @keydown.enter="toggleExpanded">
      <h4 class="header-4">{{ title }}</h4>

      <icon-ionic-chevron-down
        :aria-expanded="isExpanded"
        :color="borderColor"
        class="chevron"
        height="24"
        width="24"
      />
    </div>

    <div ref="panelRef" class="accordion-drawer-panel">
      <slot name="default" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .c-accordion-drawer {
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    border: 2px solid v-bind('borderColor');

    .accordion-drawer-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      cursor: pointer;
    }

    .chevron {
      margin-left: 4px;
      cursor: pointer;

      -moz-transition: transform 250ms;
      -webkit-transition: transform 250ms;
      transition: transform 250ms;
    }

    .chevron[aria-expanded='true'] {
      transform: rotate(180deg);
    }

    .chevron[aria-expanded='false'] {
      transform: rotate(0deg);
    }
  }

  .accordion-drawer-panel {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 16px;
    transition:
      max-height 250ms ease-in-out,
      padding 200ms ease-in-out,
      opacity 250ms ease-in-out;
  }

  .c-accordion-drawer.active {
    .accordion-drawer-panel {
      display: block;
      opacity: 1;
      padding: 0 16px 16px;
    }
  }
</style>
