/**
 * @description Track events in Meta.
 * @summary This is a composable function that tracks events in Meta. Note most of the tracking
 *          requirements are fulfilled in the ordering app. This is a placeholder for future
 *         tracking requirements.
 */
export function useTracking(): (eventName: string, eventParams?: any) => void {
  const fbq = (window as any).fbq
  const ttq = (window as any).ttq
  function trackEvent(eventName: string, eventParams?: any): void {
    // TODO once tested, the condition can be changed to `if (process.env.DEPLOY_ENV === 'production')` so pixel events
    // are only tracked in the production environment
    if (process.env.NODE_ENV !== 'local') {
      fbq('track', eventName, eventParams)
      ttq.track(eventName, eventParams)
    }
  }

  return trackEvent
}

/**
 * @description Track event when accordion is expanded.
 * @param title - The title of the accordion.
 */
export function trackingEventAccordionExpanded(title: string, id: string): void {
  const trackEvent = useTracking()

  // TikTok ViewContent event requires content_id to avoid warnings
  trackEvent('ViewContent', { content_name: title, content_id: id })
}
