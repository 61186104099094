<script lang="ts" setup>
  import type { CCardItemProps } from './CCardItem.types'

  const props = withDefaults(defineProps<CCardItemProps>(), {
    alignment: 'left',
    darkMode: false,
    loading: 'lazy',
    preload: false,
    provider: 'contentful'
  })

  if (props.data.image && !props.aspectRatio) {
    throw new Error('Aspect ratio is required when an image is provided')
  }
</script>

<template>
  <div
    :class="[
      'c-card-item',
      {
        'prop-align-left': alignment === 'left',
        'prop-align-center': alignment === 'centre',
        'prop-align-right': alignment === 'right'
      }
    ]"
  >
    <CImageBlock
      v-if="data.image"
      :alt="data.image.alt"
      :loading
      :preload
      :provider
      :src="data.image.url"
      :style="{
        '--aspect-ratio-desktop': `${aspectRatio.desktop.numerator} / ${aspectRatio.desktop.denominator}`,
        '--aspect-ratio-mobile': `${aspectRatio.mobile.numerator} / ${aspectRatio.mobile.denominator}`
      }"
      class="graphic"
    />

    <div :class="['text-container', { 'text-container-dark': darkMode }]">
      <h4 class="header-4">{{ data.title }}</h4>
      <p class="body">
        {{ data.body }}
      </p>
    </div>

    <CLink
      v-if="data.button"
      :href="data.button.href"
      :is-embedded-app-link="data.button.target === 'App'"
      :target="data.button.target === 'Tab' ? '_blank' : undefined"
      class="button-link"
    >
      <CButton :dark-mode="darkMode" :variant="darkMode ? 'secondary' : 'tertiary'">
        {{ data.button.title }}
      </CButton>
    </CLink>
  </div>
</template>

<style lang="scss" scoped>
  .c-card-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 16px;

    background-color: transparent;

    .graphic {
      aspect-ratio: var(--aspect-ratio-mobile);

      @include screenMdAndUp {
        aspect-ratio: var(--aspect-ratio-desktop);
      }

      width: 100%;
      max-width: var(--breakpoint-xl);
      border-radius: 8px;
      object-fit: cover;
    }

    .text-container {
      h4 {
        word-break: break-word;
      }
    }

    .text-container-dark {
      color: var(--color-base-white);
    }

    .button-link {
      margin-top: auto;
      margin-bottom: 2px;
    }
  }

  .c-card-item.prop-align-left {
    align-items: flex-start;

    .text-container {
      text-align: left;
    }
  }

  .c-card-item.prop-align-center {
    align-items: center;

    .text-container {
      text-align: center;
    }
  }

  .c-card-item.prop-align-right {
    align-items: flex-end;

    .text-container {
      text-align: right;
    }
  }
</style>
