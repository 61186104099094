<script lang="ts" setup>
  import type { ImgLoading, MediaRatios } from '@/types/media'
  import type { TypeImageComponent } from '@/types/contentful'

  const props = withDefaults(
    defineProps<{
      data: TypeImageComponent<'WITHOUT_UNRESOLVABLE_LINKS', string>
      loading?: ImgLoading
    }>(),
    {
      loading: 'lazy'
    }
  )

  const ratios: MediaRatios<'XXS' | 'XS' | 'S' | 'M' | 'L' | 'XL'> = {
    XXS: {
      desktop: {
        numerator: 16,
        denominator: 1
      },
      mobile: {
        numerator: 8,
        denominator: 1
      }
    },
    XS: {
      desktop: {
        numerator: 4,
        denominator: 1
      },
      mobile: {
        numerator: 3,
        denominator: 1
      }
    },
    S: {
      desktop: {
        numerator: 3,
        denominator: 1
      },
      mobile: {
        numerator: 2,
        denominator: 1
      }
    },
    M: {
      desktop: {
        numerator: 9,
        denominator: 4
      },
      mobile: {
        numerator: 1,
        denominator: 1
      }
    },
    L: {
      desktop: {
        numerator: 5,
        denominator: 2
      },
      mobile: {
        numerator: 4,
        denominator: 3
      }
    },
    XL: {
      desktop: {
        numerator: 2,
        denominator: 1
      },
      mobile: {
        numerator: 5,
        denominator: 6
      }
    }
  }

  const assetSrc = computed<string | undefined>(() => {
    return props.data.fields.image?.fields.file?.url
  })
</script>

<template>
  <section v-if="assetSrc" class="m-image">
    <CImageBlock
      :alt="data.fields.image?.fields.description"
      :loading
      :ratio="data.fields.ratio"
      :ratios
      :src="assetSrc"
    />
  </section>
</template>

<style lang="scss" scoped>
  .m-image {
    background-color: v-bind('data.fields.base?.fields.backgroundColour');
  }
</style>
